<template>
    <Page
        :title="title"
        icon="mdi-checkbox-marked-outline"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
        class-extra="mx-auto"
    >
        <bill-detail
            v-if="billApprove"
            :billApprove="billApprove"
            :click-done="clickDone"
            :folders="[]"
        />
    </Page>
</template>

<script>
import api from '@/api';
import BillDetail from '@/views/accounts/bill/approve/BillDetail';

export default {
    name: 'APApprove',
    components: { BillDetail },
    data() {
        return {
            loadingMessage: 'Retrieving results.',
            isShowLoading: false,
            errorMessage: '',
            isShowError: false,
            title: 'AP Lookup',
            billApprove: null,
            redirectUrl: null,
        };
    },

    methods: {
        async getApprove() {
            console.log('params', this.$route.params.accountsId)
            const result = await api.get(this.$store, `bill/approve/accounts/${this.$route.params.accountsId}`);
            if (result.data) this.billApprove = result.data;
            console.log("loaded bill approve - ", this.billApprove);
        },
        clickDone() {
            if (this.redirectUrl) {
                window.location.href = this.redirectUrl;
            }
        }
    },
    async mounted() {
        console.log(this.query);
        const { redirecturl: redirectUrl } = this.$route.query;

        this.redirectUrl = redirectUrl;

        await this.getApprove();
    },
};
</script>
<style>
.v-badge__badge {
    color: black;
}
</style>
